import axios from 'axios';

axios.defaults.baseURL = 'https://panel.citymallavm.com';
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const getAuthToken = () => {
    return window.localStorage.getItem('auth_token');
}

export const setAuthToken = (token) => {
    window.localStorage.setItem('auth_token', token);
}

export const removeAuthToken = () => {
    window.localStorage.removeItem('auth_token');
}

export const isTokenExpired = () => {
    const token = getAuthToken();
    if (!token) {
        return true;
    }

    const decoded = JSON.parse(atob(token.split('.')[1]));
    const expirationDate = decoded.exp * 1000;
    return Date.now() > expirationDate;
}


export const request = (method, url, data) => {
    const token = getAuthToken();

    let headers = {};
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    return axios({
        method,
        headers,
        url,
        data
    });
};