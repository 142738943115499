// HomePage.js

import React from "react";
import Nav from "./Nav.js";
import {Link} from "react-router-dom";
import {FaSignOutAlt} from "react-icons/fa";

export function HomePage() {
    return (
        <>
            <Nav showLogoutButton={false}/>
            <div className="home-page">
                <div className="content">
                    <div className="buttons">
                        <a href="/list" className="large-button">Anketleri Görüntüle</a>
                    </div>
                </div>
            </div>
        </>
    );
}
