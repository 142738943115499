import React from 'react'
import Nav from '../app/Nav'
import './Layout.css'

function Layout({ children }) {
  return (
      <div className='flex-container'>
        <Nav className='header'/>
        <div className="contents">
          {children}
        </div>
      </div>
  )
}

export default Layout