// SubmitContent.js

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Nav from "../Nav.js";
import {FaArrowLeft, FaSignOutAlt} from "react-icons/fa"; // İcon import ediliyor
import { request } from "../../util/axious_helper";
import "./SubmitContent.css"
import {Button} from "react-bootstrap";

export function SubmitListContent() {
    const [surveys, setSurveys] = useState([]);

    useEffect(() => {
        request("GET", "/api/survey/all")
            .then((response) => {
                setSurveys(response.data);
            })
            .catch((error) => {
                console.log('error: ');
                console.log(error);
            });
    }, []);

    return (
        <div>
            <Nav>
                <Link to={`/`} className="btn btn-danger">
                    <FaSignOutAlt className="mr-2" />
                    Ana Sayfa
                </Link>)
            </Nav>
            {/*<nav className="navbar">*/}
            {/*    <div className="logo" style={{ flex: 5 }}>*/}
            {/*        <img src={process.env.PUBLIC_URL + '/logo4.png'} alt="Citymall Logo" />*/}
            {/*    </div>*/}
            {/*    <h1 className="page-title" style={{ flex: 15, marginRight: '300px' }}>CITYMALL ANKET SİSTEMİ</h1>*/}
            {/*    /!* *!/*/}

            {/*        <div style={{ flex: 1.5 }}>*/}
            {/*            <Link to={`/`} className="btn btn-danger">*/}
            {/*                <FaSignOutAlt className="mr-2" />*/}
            {/*                Ana Sayfa*/}
            {/*            </Link>*/}
            {/*        </div>*/}
            {/*    )*/}
            {/*</nav>*/}
            <div className="container mt-5"> {/* Yukarıda boşluk bırakma */}
                <div className="row">
                    {surveys.map((survey, index) => (
                        <div key={index} className="col-md-4 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">{survey.title}</h5>
                                    <p className="card-text">{survey.description}</p>
                                    <Link to={`/list?id=${survey.id}`} className="btn btn-primary">
                                        Anketi Cevaplamaya Başla
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
