// Nav.js

import React from "react";
import { Link } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";

function Nav(props) {
    console.log(props)
    return (
        <nav className="navbar">
            <div className="logo center-align">
                <img src={process.env.PUBLIC_URL + '/logo4.png'} alt="Citymall Logo" />
            </div>
            <div className="page-title ">
                <h1>CITYMALL ANKET SİSTEMİ</h1>
            </div>
                <div className="logout-container center-align">
                   {props.children}
                </div>
        </nav>
    );
}

export default Nav;
