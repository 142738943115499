import './App.css';
import React from "react";
import {SubmitContent} from "./content/SubmitContent";
import {SubmitListContent} from "./content/SubmitListContent";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {HomePage} from "./HomePage";

function App() {
    console.log("App render")

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" Component={HomePage}/>
                <Route path="/list" Component={View}/>
            </Routes>
        </BrowserRouter>
    )
}

function View() {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id")

    console.log("View render")

    if (!id) {
        console.log("id not found")
        return <SubmitListContent/>
    }

    return <SubmitContent id={id}/>
}

export default App;
