import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Form, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { FaArrowLeft, FaCheck, FaSignOutAlt } from 'react-icons/fa';
import { request } from "../../util/axious_helper";
import "../App.css";
import { Link } from "react-router-dom"; // Add your CSS file for additional styling
import Nav from "../Nav.js";
import Layout from "../../components/Layout";


export function SubmitContent({ id }) {
    const [survey, setSurvey] = useState({});
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [showCompletionMessage, setShowCompletionMessage] = useState(false);

    console.log(id);

    useEffect(() => {
        request("GET", "/api/survey/get/" + id)
            .then((response) => {
                setSurvey(response.data);
                setQuestions([...response.data.textInputQuestions, ...response.data.numberInputQuestions, ...response.data.multipleChoiceQuestions].sort((a, b) => a.ordinal > b.ordinal ? 1 : -1));
                console.log(response.data);
            }).catch((error) => {
                console.log('error: ');
                console.log(error);
            });
    }, []);

    function handleAnswerSubmit() {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            request("POST", "/api/survey/submit", {
                "surveyId": survey.id,
                "textInputAnswers": answers.filter((answer) => answer.type === "TEXT_INPUT"),
                "numberInputAnswers": answers.filter((answer) => answer.type === "NUMBER_INPUT"),
                "multipleChoiceAnswers": answers.filter((answer) => answer.type === "MULTIPLE_CHOICE")
            }).then((response) => {
                console.log(response);
                setShowCompletionMessage(true);
            }).catch((error) => {
                console.log('error: ');
                console.log(error);
                alert('Bir şeyler ters gitti');
            });
        }
    }

    function prepareQuestion(question, index) {
        if (index !== currentQuestionIndex) {
            return null;
        }

        let content = null;

        if (question.type === "TEXT_INPUT") {
            content = (
                <div>
                    <FormGroup controlId={`textInput-${index}`} className="mx-5">
                        <FormLabel>{index + 1}.SORU : {question.name}</FormLabel>
                        <FormControl
                            type="text"
                            value={answers[index]?.value}
                            onChange={(e) => {
                                let newAnswers = [...answers];
                                newAnswers[index] = {
                                    "questionId": question.id,
                                    "value": e.target.value,
                                    "type": "TEXT_INPUT"
                                };
                                setAnswers(newAnswers);
                            }}
                        />
                        <div className="d-flex justify-content-center mt-3">
                            <Button variant="primary" onClick={handleAnswerSubmit}>
                                {currentQuestionIndex < questions.length - 1 ? 'Yanıtla' : 'Anketi Tammala'} <FaCheck className="me-2" />
                            </Button>
                        </div>
                    </FormGroup>
                </div>

            );
        } else if (question.type === "NUMBER_INPUT") {
            content = (
                <div>
                    <FormGroup controlId={`numberInput-${index}`} className="mx-5">
                        <FormLabel>{index + 1}.SORU {question.name}</FormLabel>
                        <FormControl
                            type="number"
                            value={answers[index]?.value}
                            onChange={(e) => {
                                let newAnswers = [...answers];
                                newAnswers[index] = {
                                    "questionId": question.id,
                                    "value": e.target.value,
                                    "type": "NUMBER_INPUT"
                                };
                                setAnswers(newAnswers);
                            }}
                        />
                        <div className="d-flex justify-content-center mt-3">
                            <Button variant="primary" onClick={handleAnswerSubmit}>
                                {currentQuestionIndex < questions.length - 1 ? 'Yanıtla' : 'Anketi Tammala'} <FaCheck className="me-2" />
                            </Button>
                        </div>
                    </FormGroup>
                </div>

            );
        } else if (question.type === "MULTIPLE_CHOICE") {
            if (!answers[index]) {
                let newAnswers = [...answers];
                newAnswers[index] = {
                    "questionId": question.id,
                    "optionId": question.options[0].id,
                    "type": "MULTIPLE_CHOICE"
                };
                setAnswers(newAnswers);
            }
            content = (
                <div>
                    <FormGroup controlId={`multipleChoice-${index}`} className="mx-5">
                        <FormLabel>{index + 1}.SORU {question.name}</FormLabel>
                        <FormControl
                            as="select"
                            value={answers[index]?.optionId}
                            onChange={(e) => {
                                let newAnswers = [...answers];
                                newAnswers[index] = {
                                    "questionId": question.id,
                                    "optionId": e.target.value,
                                    "type": "MULTIPLE_CHOICE"
                                };
                                setAnswers(newAnswers);
                            }}
                        >
                            {question.options.map((option, optionIndex) => {
                                return (
                                    <option key={optionIndex} value={option.id}>
                                        {option.name}
                                    </option>
                                );
                            })}
                        </FormControl>
                        <div className="d-flex justify-content-center mt-3">
                            <Button variant="primary" onClick={handleAnswerSubmit}>
                                {currentQuestionIndex < questions.length - 1 ? 'Yanıtla' : 'Anketi Tammala'} <FaCheck className="me-2" />
                            </Button>
                        </div>
                    </FormGroup>

                </div>
            );
        }

        return (
            <div key={index} className="question-wrapper">
                {content}
            </div>
        );
    }

    return (
        <Layout>
            <div className="w-100" >
                <div className="d-flex justify-content-between mx-5 mt-3">
                    <Link to={`/list`} className="btn btn-secondary">
                        <FaArrowLeft className="mr-2" />
                        Geri
                    </Link>
                    <Link to={`/`} className="btn btn-danger">
                        <FaSignOutAlt className="mr-2" />
                        Ana Sayfa
                    </Link>
                </div>
                <h1 className="text-center mt-3">{survey.title}</h1>
                <h3 className="text-center mt-2 mb-4">{survey.description}</h3>
            </div>
            {showCompletionMessage ? (
                <div className="text-center">
                    <h4>
                        <span className="fireworks-icon">
                            <i className="bi bi-emoji-heart-eyes"></i>
                        </span>
                        Anketimize katıldığınız için teşekkür ederiz!
                        <span className="fireworks-icon">
                            <i className="bi bi-emoji-heart-eyes"></i>
                        </span>
                    </h4>
                    <img
                        src={process.env.PUBLIC_URL + '/logo4.png'} alt="Citymall Logo"
                        width="500"
                        height="240"
                        className="d-inline-block align-top"
                    />
                </div>
            ) : (
                <Form className="d-flex flex-column justify-content-center mt-5">
                    {questions.map((question, index) => {
                        return prepareQuestion(question, index);
                    })}
                </Form>
            )}
        </Layout>

    );
}
